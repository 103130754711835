import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import store from "@/store";
import router from "@/router";
import UserService from "@/store/user/service";
import staticMenus from "@/utils/menus";

import { UserData, UserLoginParams, MenubarComponent, MenubarItem, UserParams, User } from "@/store/user/types";
import { GojusError } from "@/services/gojus/types";
import { ToastParams } from "../global/types";
import { Pagination, PaginationRow } from "../types";
import { Employee } from "../department/employee/types";

@Module({ namespaced: true })
export class UserModule extends VuexModule {
  all: User[] = [];
  current: UserData = {
    id: 0,
    username: "",
    first_name: "",
    last_name: "",
    is_active: true,
    cd_correspondente: 0,
    estabelecimentos: [],
    grupos: [],
    menus: null,
    permissoes: null,
  };
  employee: Employee | null = null;
  session = { active: Boolean(localStorage.getItem("TOKEN")) };
  menubar: MenubarComponent = [];
  pagination: Pagination = { row: 10, total: 0, current: 1 };
  emailVerify: string | null = null;

  service = new UserService();
  filters: any = {};

  permissions = {
    superuser: false,
    integrationModule: false,
    integrationFound: false,
    processModule: false,
    collectionModule: false,
    logisticsModule: false,
    analyticsModule: false,
    financialModule: false,
    managerModule: false,
    taskModule: false,
    taskCoordinator: false,
    generalTaskCoordinator: false,
    correspondentUser: false,
    petitionFeature: false,
    newProcessFeature: false,
    writFeature: false,
    ui: {
      financial: {
        summary: {
          values: true,
        },
      },
    },
  };

  @Mutation
  updatePaginationRow(row: PaginationRow) {
    this.pagination.row = row;
  }

  @Mutation
  updatePaginationTotal(total: number) {
    this.pagination.total = total;
  }

  @Mutation
  updateCurrentPaginationPage(current: number) {
    this.pagination.current = current;
  }

  @Mutation
  updatePermissions(user: UserData) {
    const userGroups = (user?.grupos || []).map((group) => ({
      ...group,
      nm_grupo: group.nm_grupo?.toLowerCase(),
    }));

    const hasGroup = (name: string) => {
      return userGroups.some((group) => group.nm_grupo?.includes?.(name));
    };

    this.permissions.superuser = hasGroup("midgard");
    this.permissions.integrationModule = hasGroup("integração") || this.permissions.superuser;
    this.permissions.integrationFound = hasGroup("processos descobertos") || this.permissions.superuser;
    this.permissions.newProcessFeature = hasGroup("processos descobertos") || this.permissions.superuser;
    // this.permissions.petitionFeature = hasGroup("peticionamento") || this.permissions.superuser;
    this.permissions.petitionFeature =
      (hasGroup("peticionamento") && user.estabelecimentos.map((item) => item.pk).includes(2)) ||
      this.permissions.superuser;
    this.permissions.writFeature = hasGroup("alvará") || this.permissions.superuser;
    this.permissions.processModule = hasGroup("processos") || this.permissions.superuser;
    this.permissions.collectionModule = hasGroup("cobrança") || this.permissions.superuser;
    this.permissions.logisticsModule = hasGroup("logistica") || this.permissions.superuser;
    this.permissions.analyticsModule = hasGroup("analytics") || this.permissions.superuser;
    this.permissions.financialModule = hasGroup("financeiro") || this.permissions.superuser;
    this.permissions.managerModule = hasGroup("gestor") || this.permissions.superuser;
    this.permissions.taskModule = hasGroup("tarefa") || this.permissions.superuser;
    this.permissions.taskCoordinator = hasGroup("tarefa-coordenador") || this.permissions.superuser;
    this.permissions.generalTaskCoordinator = hasGroup("coordenador geral") || this.permissions.superuser;
    this.permissions.correspondentUser = hasGroup("correspondente") && !this.permissions.superuser;
  }

  @Mutation
  updateUser() {}

  @Mutation
  updateUsers(users: User[]) {
    this.all = users;
  }

  get isAuthenticated() {
    return this.session.active && this.current.username !== "";
  }

  @Mutation
  updateMenubar() {
    const menubar: MenubarItem[] = [];
    const menus: MenubarItem[] = JSON.parse(JSON.stringify(staticMenus));

    for (const menu of menus) {
      if (!menu.items?.length) {
        if (this.current.permissoes?.includes(menu.permission || "") || this.permissions.superuser) {
          menubar.push(menu);
        }

        continue;
      }

      const submenus: MenubarItem[] = [];

      for (const submenu of menu.items || []) {
        const submenus2: MenubarItem[] = [];

        for (const submenu2 of submenu.items || []) {
          if (this.current.permissoes?.includes(submenu2.permission || "") || this.permissions.superuser) {
            const route = submenu2.to?.name;

            if (route?.includes("command:::") || this.permissions.superuser) {
              submenu2.command = () => store.dispatch(`report/${route?.replace("command:::", "")}`);
              delete submenu2.to;
            }

            submenus2.push(submenu2);
          }
        }

        if (submenu.to?.name?.includes("command:::")) {
          const command = submenu.to.name.replace("command:::", "");
          submenu.command = () => store.dispatch(`report/${command}`);
          delete submenu.to;
        }

        if (submenus2.length) {
          submenus.push({ ...submenu, items: submenus2 });
          continue;
        }

        if (this.current.permissoes?.includes(submenu.permission || "") || this.permissions.superuser) {
          submenus.push(submenu);
        }
      }

      if (submenus.length) {
        menubar.push({ ...menu, items: submenus });
      }
    }

    if (menubar.length > 3) {
      this.menubar = menubar;
    } else {
      const inlineMenus: MenubarItem[] = [];

      for (const menu of menubar) {
        if (menu.items?.length) {
          inlineMenus.push(...menu.items.map((item) => ({ ...item, label: item.inlineLabel || item.label })));
        } else {
          inlineMenus.push({ ...menu, label: menu.inlineLabel || menu.label });
        }
      }

      this.menubar = inlineMenus;
    }
  }

  @Mutation
  updateCurrent(current: UserData) {
    this.current = current;
    localStorage.setItem("USERNAME", current.username || "");
    localStorage.setItem("USER_NAME", `${current.first_name} ${current.last_name}` || "");
  }

  @Mutation
  updateCurrentEmployee(employee: Employee | null) {
    this.employee = employee;
  }

  @Mutation
  updateSessionState(session: { active: boolean; token?: string }) {
    this.session.active = session.active;

    if (session.token) {
      localStorage.setItem("TOKEN", session.token);
      localStorage.setItem("TOKEN_DATE", new Date().toISOString());
    } else {
      const companyId = localStorage.getItem("CD_EMPRESA") || "";
      localStorage.clear();

      if (companyId) {
        localStorage.setItem("CD_EMPRESA", companyId);
      }

      router.go(0);
    }
  }

  @Mutation
  updateFilters(value: any) {
    this.filters = value || {};
  }

  @Action
  async getUserData() {
    try {
      const { data } = await this.service.getUserData();

      this.context.commit("updatePermissions", data);

      if (this.permissions.superuser) {
        const establishments = await this.context.dispatch("company/getAll", {}, { root: true });
        data.estabelecimentos = establishments?.map((item: any) => ({
          cnpj: item.cnpj,
          dt_fim_teste: item.dt_fim_teste || null,
          nm_nome_fantasia: item.nm_nome_fantasia,
          nm_razao_social: item.nm_razao_social,
          pk: item.id,
        }));
      }

      this.context.commit(
        "company/updateCompanies",
        data.estabelecimentos.sort((companyA, companyB) => {
          try {
            const companyNameA = companyA.nm_fantasia || companyA.nm_nome_fantasia || "";
            const companyNameB = companyB.nm_fantasia || companyB.nm_nome_fantasia || "";

            return companyNameA > companyNameB ? -1 : 1;
          } catch {
            return 0;
          }
        }),
        { root: true }
      );
      this.context.commit("updateCurrent", data);
      this.context.commit("updateMenubar", data.menus);
      this.context.commit("company/updateCurrent", {}, { root: true });
      this.context.commit("debtor/updatePermissions", data.permissoes, { root: true });

      if (data?.id && !this.permissions.correspondentUser) {
        const response = await this.context.dispatch(
          "department/employee/search",
          { cd_authuser: data.id },
          { root: true }
        );

        if (response?.data?.results?.length) {
          this.context.commit("updateCurrentEmployee", response.data.results[0]);
        }
      }

      if (this.permissions.correspondentUser) {
        localStorage.setItem("IS_CORRESPONDENT", "true");
        localStorage.removeItem("CD_EMPRESA");
      }

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getToken(params: UserLoginParams) {
    try {
      const { data } = await this.service.getToken(params.username, params.password);

      const session = { active: true, token: data.token };
      this.context.commit("updateSessionState", session);

      return data;
    } catch (error) {
      const gojusError: GojusError = {
        messages: [
          {
            summary: "Não foi possível fazer login com as credenciais fornecidas!",
            severity: "error",
          },
        ],
        isCancel: false,
        isGojusError: true,
      };

      await this.context.dispatch("global/handleError", gojusError, {
        root: true,
      });
    }
  }

  @Action
  async refreshToken(params?: UserLoginParams) {
    try {
      const response = await this.service.refreshToken(params?.username, params?.password);

      const session = { active: true, token: response.data.token };
      this.context.commit("updateSessionState", session);

      return response.data;
    } catch (error: any) {
      await this.context.dispatch(
        "global/handleError",
        [{ summary: "Sua sessão expirou, faça login para continuar!", severity: "error" }],
        { root: true }
      );
      setTimeout(() => {
        this.context.commit("updateSessionState", { active: false });
      }, 1000);
    }
  }

  @Action
  async forgotPassword(params: { ds_email: string }) {
    try {
      const { data } = await this.service.forgotPassword(params.ds_email);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Um link para redefinir sua senha foi enviado para seu email.",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async resetPassword(params: any) {
    try {
      const { data } = await this.service.resetPassword(params.password, params.token);

      const message: ToastParams[] = [
        {
          severity: "success",
          summary: "Senha redefinida com sucesso",
        },
      ];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async get(params: UserParams) {
    try {
      const { data } = await this.service.get(params.cd);
      this.context.commit("updateUser");

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAll(params?: UserParams) {
    try {
      const { data } = await this.service.getAll(
        {
          page: this.pagination.current,
          nr_por_pagina: this.pagination.row,
          paginacao: true,
        },
        { ...this.filters, ...params?.filters }
      );
      this.context.commit("updateUsers", data.results);
      this.context.commit("updatePaginationTotal", data.count);

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async create(params: UserParams) {
    try {
      await this.service.create(params.form);
      this.context.commit("updateUser");

      const response = await this.service.getAll(
        { paginacao: true, page: 1, nr_por_pagina: 1 },
        { username: params.form.username }
      );
      const user = response?.data?.results?.[0] || null;

      return user;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(params: UserParams) {
    try {
      const { data } = await this.service.edit(params.cd, params.form);
      this.context.commit("updateUser");

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async delete(params: UserParams) {
    try {
      const { data } = await this.service.delete(params.cd);

      const message: ToastParams[] = [{ severity: "success", summary: "Usuário excluído com sucesso" }];
      this.context.commit("global/updateToast", message, { root: true });
      this.context.commit("updateUser");

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default UserModule;
