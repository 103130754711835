import { reactive } from "vue";
import { defineStore } from "pinia";

import { gojus } from "@/services/gojusv2";
import { VUE_APP_API_BASE_URL } from "@/utils/env";

import { getRandomColorByString, getTextColorForBackground } from "@/views/analytics/utils";
import type { AppTagFormT, AppTagT } from "@/stores/app/tag/types";

export const useAppTagStore = defineStore("app-tag", () => {
  const state = reactive({
    all: [] as AppTagT[],
  });

  const cache = reactive<{ [key: number]: AppTagT }>({});

  const loading = reactive({
    retrieve: false,
    all: false,
    create: false,
    update: false,
    remove: false,
  });

  const appTagUtils = useAppTagUtils();

  async function retrieve(payload: { id: number; cache?: boolean }) {
    if (payload.cache !== false && cache[payload.id]) {
      return cache[payload.id];
    }

    loading.retrieve = true;
    const response = await gojus.get<AppTagT>(`v1/etiquetas/${payload.id}/`, {
      apiUrl: VUE_APP_API_BASE_URL,
    });
    loading.retrieve = false;

    if (!response.data) {
      return null;
    }

    const result = appTagUtils.handle([response.data]);
    const tag = result[0];

    cache[payload.id] = tag;

    return tag;
  }

  async function all(payload?: { filters?: Record<string, any>; save?: boolean }) {
    loading.all = true;
    const response = await gojus.get<AppTagT[]>("v1/etiquetas/", {
      ...payload?.filters,
      apiUrl: VUE_APP_API_BASE_URL,
    });
    loading.all = false;

    if (!response.data) {
      return [];
    }

    const items = appTagUtils.handle(response.data);

    if (payload?.save !== false) {
      state.all = items;
    }

    items.forEach((item) => {
      cache[item.id] = item;
    });

    return items;
  }

  async function create(payload: { form: AppTagFormT }) {
    loading.create = true;
    const response = await gojus.post<AppTagT>("v1/etiquetas/", appTagUtils.apiForm(payload.form), {
      apiUrl: VUE_APP_API_BASE_URL,
    });
    loading.create = false;

    if (!response.data) {
      return { success: false };
    }

    const item = appTagUtils.handle([response.data])[0];

    return { success: true, item };
  }

  async function update(payload: { id: number; form: AppTagFormT }) {
    loading.update = true;
    const response = await gojus.patch<AppTagT>(`v1/etiquetas/${payload.id}/`, appTagUtils.apiForm(payload.form), {
      apiUrl: VUE_APP_API_BASE_URL,
    });
    loading.update = false;

    if (!response.data) {
      return { success: false };
    }

    const item = appTagUtils.handle([response.data])[0];

    cache[payload.id] = { ...cache[payload.id], ...item };
    const tagId = state.all.findIndex((tag) => tag.id === payload.id);
    if (tagId !== -1) {
      state.all[tagId] = cache[payload.id];
    }

    return { success: true, item };
  }

  async function remove(payload: { id: number }) {
    loading.remove = true;
    const response = await gojus.delete(`v1/etiquetas/${payload.id}/`, {
      useJson: false,
      apiUrl: VUE_APP_API_BASE_URL,
    });
    loading.remove = false;

    if (!response.data) {
      return { success: false };
    }

    return { success: true };
  }

  return {
    state,
    loading,
    retrieve,
    all,
    create,
    update,
    remove,
  };
});

export function useAppTagUtils() {
  function form(item?: AppTagT | null): AppTagFormT {
    return {
      nm_etiqueta: (item?.nm_etiqueta || "").toUpperCase(),
      cor: item?.cor?.replace("#", "").toLowerCase() || "",
    };
  }

  function apiForm(form: AppTagFormT): AppTagFormT {
    return {
      ...form,
      nm_etiqueta: form.nm_etiqueta.trim().toUpperCase(),
      cor: (form.cor.includes("#") ? form.cor : `#${form.cor}`).toLowerCase(),
    };
  }

  function handle(items: AppTagT[]) {
    return items
      .map((item) => {
        const textColor = getTextColorForBackground(item.cor);
        return {
          ...item,
          nm_etiqueta: item.nm_etiqueta.trim().toUpperCase(),
          textColor: textColor.color,
          isWhite: textColor.isWhite,
          showOption: false,
        };
      })
      .sort((itemA, itemB) => (itemA.nm_etiqueta > itemB.nm_etiqueta ? 1 : -1));
  }

  return {
    form,
    apiForm,
    handle,
    textColor: getTextColorForBackground,
    backgroundColor: getRandomColorByString,
  };
}
