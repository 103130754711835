<template>
  <c-form-template
    class="c-task-form"
    :loading="loading"
    :isFlex="true"
    @click-back="$emit('click-back')"
    @submit="onSubmit"
  >
    <c-dashboard-section-template :useOptions="true">
      <template #title>Informações Gerais</template>
      <template #options-container>
        <span class="app-info" style="font-weight: 500">
          <i class="fas fa-info-circle"></i>
          Módulo {{ getTaskOriginModules(formType)?.label || "" }}
        </span>
      </template>

      <template #fields>
        <c-form-field-template width="240px">
          <template #label>Tipo de Tarefa <b class="is-required" v-if="formType !== 'administrative'"></b></template>
          <p-dropdown
            v-model="cd_tipo_tarefa"
            :options="taskTypes"
            ref="taskType"
            dataKey="id"
            optionLabel="nm_tipo_tarefa"
            optionValue="id"
            :class="[{ error: validation.field === 'taskType' && !cd_tipo_tarefa }]"
            :placeholder="formType === 'administrative' ? 'ADMINISTRATIVA' : `Selecione um Tipo de Tarefa`"
            :disabled="formType === 'administrative'"
            :required="formType !== 'administrative'"
          />
        </c-form-field-template>
        <c-form-field-template width="calc(100% - 240px)">
          <template #label>Titulo da Tarefa <b class="is-required"></b></template>
          <p-input-text v-model="nm_titulo" autofocus required placeholder="Informe um Título para a Tarefa" />
        </c-form-field-template>

        <c-form-field-template
          width="50%"
          class="lawsuit-search"
          v-if="!['debtor', 'administrative'].includes(formType)"
        >
          <template #label>
            Número do Processo <b class="is-required" v-if="currentTaskType !== 'operacional'"></b>
          </template>
          <span class="p-input-icon-right" style="width: 100%">
            <p-auto-complete
              v-model="lawsuitQuery"
              :suggestions="lawsuits"
              field="nr_processo"
              :minLength="3"
              :dropdown="true"
              :required="currentTaskType !== 'operacional'"
              placeholder="Pesquise pelo Número do Processo"
              @complete="searchLawsuit"
              @item-select="onLawsuitSelected"
            >
              <template #item="{ item }">
                <div class="country-item">
                  <div>{{ formatProcessNumberInCnjPattern(item.nr_processo) }}</div>
                </div>
              </template>
            </p-auto-complete>
            <i class="fas fa-search"></i>
          </span>
        </c-form-field-template>
        <c-form-field-template width="50%" v-if="!isAdministrativeTask">
          <template #label>Cliente <b class="is-required"></b></template>
          <p-auto-complete
            v-model="customerQuery"
            :suggestions="customers"
            field="nm_cliente"
            :minLength="1"
            required
            forceSelection
            placeholder="Pesquise pelo nome do Cliente"
            @complete="searchCustomer"
            @item-select="selectCustomer"
          />
        </c-form-field-template>

        <c-form-field-template v-if="!['debtor', 'administrative'].includes(formType)" width="50%">
          <template #label>Parte Contrária</template>
          <p-input-text v-model="nm_parte_contraria" placeholder="Informe o nome da Parte Contrária" />
        </c-form-field-template>
        <c-form-field-template v-if="formType === 'debtor'" width="50%">
          <template #label>Devedor</template>
          <p-auto-complete
            v-model="debtorQuery"
            :suggestions="debtors"
            field="nm_devedor"
            :minLength="1"
            forceSelection
            @complete="searchDebtor"
            @item-select="onDebtorSelected"
          />
        </c-form-field-template>

        <c-form-field-template width="20%">
          <template #label>Estado</template>
          <p-dropdown
            v-model="cd_estado"
            :options="states"
            dataKey="id"
            optionLabel="nm_estado"
            optionValue="id"
            placeholder="Selecione um Estado"
            filter
          />
        </c-form-field-template>

        <c-form-field-template width="30%">
          <template #label>Comarca</template>
          <p-dropdown
            v-model="cd_cidade"
            ref="city"
            :class="[cityLoading ? 'loading' : '', validation.field === 'city' ? 'error' : '']"
            :options="cities"
            dataKey="id"
            optionLabel="nm_cidade"
            optionValue="id"
            :disabled="isEmptyCities || cityLoading"
            placeholder="Selecione uma Comarca"
            filter
            required
            @change="validation.field === 'city' ? (validation.field = null) : ''"
          />
        </c-form-field-template>

        <template v-if="isHearingTask && !isEditForm">
          <c-form-field-template width="30%">
            <template #label>Nome do Autor</template>
            <p-input-text v-model="nm_autor" placeholder="Informe o nome do Autor" />
          </c-form-field-template>
          <c-form-field-template width="30%" v-if="!isAdministrativeTask">
            <template #label>Nome do Réu</template>
            <p-input-text v-model="nm_reu" placeholder="Informe o nome do Reú" />
          </c-form-field-template>
          <c-form-field-template width="20%">
            <template #label>Foro</template>
            <p-dropdown
              v-model="cd_foro"
              ref="forum"
              dataKey="id"
              :class="[validation.field === 'forum' ? 'error' : '']"
              :options="forums"
              optionLabel="nm_foro"
              optionValue="id"
              filter
              filterPlaceholder="Pesquise pelo nome do Foro"
              placeholder="Selecione um Foro"
              emptyFilterMessage="Nenhum resultado"
              required
              @change="if (validation.field === 'forum') validation.field = null;"
            />
          </c-form-field-template>
          <c-form-field-template width="20%">
            <template #label>Tipo de Audiência</template>
            <div class="p-inputgroup">
              <p-dropdown
                v-model="cd_tipo"
                ref="type"
                :class="[validation.field === 'type' ? 'error' : '']"
                :options="hearingTypes"
                optionLabel="nm_tipo_ato"
                optionValue="id"
                filter
                filterPlaceholder="Pesquise pelo nome do Tipo de Ato"
                placeholder="Selecione um Tipo de Ato"
                emptyFilterMessage="Nenhum resultado"
                required
                @change="if (validation.field === 'type') validation.field = null;"
              />
              <p-button
                class="p-button-success"
                icon="fas fa-plus"
                title="Cadastrar um novo Tipo de Ato"
                @click="onAskingToCreateLogisticsActType('AU')"
              />
            </div>
          </c-form-field-template>

          <c-form-field-template width="140px">
            <template #label>Audiência Virtual</template>
            <p-dropdown
              v-model="ie_processo_virtual"
              :options="booleanOptions"
              optionLabel="label"
              optionValue="value"
            />
          </c-form-field-template>
          <c-form-field-template width="180px">
            <template #label>Solicitar correspondente</template>
            <p-dropdown
              v-model="hasCorrespondent"
              :options="booleanOptions"
              optionLabel="label"
              optionValue="value"
              :disabled="!$store.state.user.permissions.logisticsModule"
            />
          </c-form-field-template>
          <c-form-field-template width="140px">
            <template #label>Data <b class="is-required"></b></template>
            <p-calendar
              v-model="dt_fatal"
              :class="[{ error: validation.field === 'dt_fatal' }]"
              ref="dt_fatal"
              dateFormat="dd/mm/yy"
              placeholder="dd/mm/aaaa"
              :minDate="new Date()"
              :locale="calendarLocale"
              class="show-last"
              required
              @input="
                dt_fatal = addDateMask(dt_fatal);
                handleDateInput('dt_fatal');
                validation.field === 'dt_fatal' ? (validation.field = null) : '';
              "
            />
          </c-form-field-template>
          <c-form-field-template width="120px">
            <template #label>Hora</template>
            <p-input-mask v-model="hr_vencimento" mask="99:99" required />
          </c-form-field-template>
        </template>
        <template v-else>
          <c-form-field-template v-if="!isEditForm || hasPermissionCoordinator" width="calc(50% - 380px)">
            <template #label>Prazo Fatal <b class="is-required"></b></template>
            <p-calendar
              v-model="dt_fatal"
              :class="[{ error: validation.field === 'dt_fatal' }]"
              ref="dt_fatal"
              dateFormat="dd/mm/yy"
              :minDate="new Date()"
              :locale="calendarLocale"
              class="show-last"
              placeholder="dd/mm/aaaa"
              required
              @input="
                dt_fatal = addDateMask(dt_fatal);
                handleDateInput('dt_fatal');
                validation.field === 'dt_fatal' ? (validation.field = null) : '';
              "
            />
          </c-form-field-template>
          <c-form-field-template width="200px">
            <template #label>Data do Vencimento <b class="is-required"></b></template>
            <p-calendar
              v-model="dt_vencimento"
              :class="[{ error: validation.field === 'dt_vencimento' }]"
              ref="dt_vencimento"
              dateFormat="dd/mm/yy"
              :minDate="minimumExpirationDate"
              :maxDate="maximumExpirationDate"
              :locale="calendarLocale"
              class="show-last"
              placeholder="dd/mm/aaaa"
              required
              @input="
                dt_vencimento = addDateMask(dt_vencimento);
                handleDateInput('dt_vencimento');
                getResponsibleInfos();
                validation.field === 'dt_vencimento' ? (validation.field = null) : '';
              "
            />
          </c-form-field-template>
          <c-form-field-template width="180px">
            <template #label>Hora do Vencimento <b class="is-required"></b></template>
            <p-input-mask v-model="hr_vencimento" mask="99:99" required />
          </c-form-field-template>
        </template>
        <c-form-field-template width="20%">
          <template #label>Equipe <b class="is-required"></b></template>
          <p-dropdown
            v-model="cd_equipe"
            :class="[{ error: validation.field === 'cd_equipe' }]"
            ref="cd_equipe"
            :options="teams"
            optionValue="id"
            optionLabel="nm_equipe"
            placeholder="Selecione uma Equipe"
            required
            @input="
              handleTeamInput(Number(cd_equipe));
              validation.field === 'cd_equipe' ? (validation.field = null) : '';
            "
          />
        </c-form-field-template>
        <c-form-field-template width="30%" v-if="team">
          <template #label>Responsáveis</template>
          <p-multi-select
            v-model="cd_responsaveis"
            dataKey="cd_authuser"
            :options="teamEmployees"
            optionLabel="nm_funcionario"
            optionValue="cd_authuser"
            :disabled="responsibleLoading"
            placeholder="Selecione um Responsável"
            showClear
            filter
            filterPlaceholder="Nome do responsável"
            emptyFilterMessage="Nenhum resultado"
          >
            <template #option="{ option }">
              <div class="responsible-task-item">
                <p class="responsible-task-item-name">{{ option.nm_funcionario }}</p>
                <div class="responsible-task-item-infos">
                  <span>
                    Operacionais:
                    <b>{{ option.prazos_operacional === undefined ? "?" : option.prazos_operacional }}</b>
                  </span>
                  |
                  <span>
                    Processuais:
                    <b>{{ option.prazos_processual === undefined ? "?" : option.prazos_processual }}</b>
                  </span>
                </div>
              </div>
            </template>
          </p-multi-select>
        </c-form-field-template>

        <div class="task-recurrence-option">
          <button
            class="task-recurrence-button edit"
            type="button"
            @click="() => (showRecurrenceModal = true)"
            v-if="recurrenceForm"
          >
            <i class="fa-solid fa-calendar-days"></i>
            <span>Editar recorrência</span>
          </button>
          <button class="task-recurrence-button" type="button" @click="() => (showRecurrenceModal = true)" v-else>
            <i class="fa-solid fa-calendar-days"></i>
            <span>Adicionar recorrência</span>
          </button>
        </div>

        <c-form-field-template width="100%">
          <template #label>Descrição</template>
          <p-textarea
            v-model="ds_descricao"
            :autoResize="true"
            rows="5"
            cols="auto"
            placeholder="Informe os detalhes dessa tarefa"
          />
        </c-form-field-template>
      </template>
    </c-dashboard-section-template>

    <c-dashboard-section-template class="adicional files" :useHeader="false">
      <template #fields>
        <c-form-field-template>
          <template #label>Anexos</template>
          <p-file-upload
            mode="advanced"
            chooseLabel="Adicionar"
            uploadLabel="remove"
            cancelLabel="Limpar"
            :multiple="true"
            :customUpload="true"
            ref="fileUpload"
            @uploader="handleFileUpload"
          >
            <template #empty>
              <p>Arraste e solte um arquivo aqui.</p>
            </template>
          </p-file-upload>
        </c-form-field-template>
      </template>
    </c-dashboard-section-template>

    <template #submit-text>
      <slot name="submit-text">Cadastrar</slot>
    </template>

    <c-task-recurrence-modal
      :recurrenceForm="recurrenceForm"
      @submit:recurrence="handleRecurrenceSubmit"
      @close:modal="handleCloseRecurrenceModal"
      v-if="showRecurrenceModal"
    />
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";
import CDashboardSectionTemplate from "@/components/dashboard/section.vue";
import AutoComplete from "primevue/autocomplete/AutoComplete.vue";
import CTaskRecurrenceModal from "@/views/task/components/recurrence.vue";

import { validate } from "@/views/task/utils";
import { taskStatus, PROCESSUAL, getTaskOriginModules } from "@/utils/acronyms";
import {
  calendarConfig,
  formatDate,
  formatDateEn,
  formatHours,
  formatProcessNumberInCnjPattern,
} from "@/utils/formatters";

import { Task, TaskForm, TaskFormType, TaskRecurrenceFormT, TaskType } from "@/store/task/types";
import { LogisticsCustomer } from "@/store/logistics/customer/types";
import { ModalParams, ToastParams } from "@/store/global/types";
import { City, State } from "@/store/location/types";
import { Lawsuit, LawsuitStrategy } from "@/store/lawsuit/types";
import { Debtor } from "@/store/collection/debtor/types";
import { addDateMask } from "@/utils/mask";
import { Team } from "@/store/team/types";
import { Forum } from "@/store/forum/types";
import { Employee } from "@/store/department/employee/types";

const PAutoComplete: any = AutoComplete;

@Component({
  components: { CFormTemplate, CFormFieldTemplate, CDashboardSectionTemplate, PAutoComplete, CTaskRecurrenceModal },
})
export default class CTaskForm extends Vue implements TaskForm {
  @Prop({ required: true }) readonly loading!: boolean;
  @Prop({ default: "standalone" }) readonly formType!: TaskFormType;

  @Ref() city!: any;
  @Ref() readonly fileUpload!: any;

  validation: { error: string | null; field: string | null } = { error: null, field: null };

  calendarLocale = calendarConfig;
  taskStatus = taskStatus;
  addDateMask = addDateMask;
  formatProcessNumberInCnjPattern = formatProcessNumberInCnjPattern;
  getTaskOriginModules = getTaskOriginModules;

  cd = Number(this.$route.params.cd);
  isEditForm = Boolean(this.$route.name?.includes("edit") || this.task?.id);

  cd_cliente = this.task.cd_cliente || "";
  cd_equipe = Number(this.task.cd_equipe) || "";
  cd_devedor = this.task.cd_devedor || "";
  cd_responsaveis: number[] = this.task.cd_responsaveis || [];
  cd_tipo_tarefa = this.task.cd_tipo_tarefa || 0;
  cd_expediente = this.task.cd_expediente || null;
  dt_expedicao = this.task.dt_expedicao || null;
  prazo = this.task.prazo || null;
  cd_cidade = this.task.cd_cidade || null;
  nm_cidade = this.task.nm_cidade || "";
  nm_estado = this.task.nm_estado || "";
  nm_equipe = this.task.nm_equipe || "";
  nm_titulo = this.task.nm_titulo || "";
  nm_parte_contraria = this.task.nm_parte_contraria || "";
  nr_processo = this.task.nr_processo || "";
  ds_descricao = this.task.ds_descricao || "";
  dt_vencimento = formatDate(this.task.dt_vencimento);
  dt_fatal = formatDate(this.task.dt_fatal) || "";
  hr_vencimento = this.task.hr_vencimento || "23:59";

  nm_autor = this.task.nm_autor || "";
  nm_reu = this.task.nm_reu || "";
  cd_foro = this.task.cd_foro || null;
  cd_tipo = this.task.cd_tipo || null;
  ie_processo_virtual = this.task.ie_processo_virtual ?? true;
  hasCorrespondent = true;

  cd_estado = 0;
  cities: City[] = [];
  cityLoading = false;
  customerQuery = this.task.ob_cliente?.nm_cliente || "";
  responsibleQuery: any = [];
  debtorQuery = this.task.ob_devedor?.nm_devedor || "";
  lawsuitQuery: any = this.task.nr_processo || "";
  lawsuits: Lawsuit[] = [];
  lawsuitLoading = false;
  lawsuit: null | Lawsuit = null;
  debtor: null | Debtor = null;
  files: File[] = [];
  team: Team | null = null;
  recurrenceForm: Omit<TaskRecurrenceFormT, "cd_tarefa"> | null = null;

  responsibleLoading = false;
  booleanOptions = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  showRecurrenceModal = false;

  teamEmployees: Employee[] = [];
  strategies: LawsuitStrategy[] = [];

  get task(): Task {
    return this.$store.state.task.current;
  }

  get allTaskTypes(): TaskType[] {
    return this.$store.state.task.types;
  }

  get taskTypes() {
    const items: TaskType[] = this.$store.state.task.types.filter(
      (item: TaskType) => item.nm_tipo_tarefa !== "ADMINISTRATIVO"
    );

    if (this.formType !== "lawsuit") {
      return items.filter((item) => item.nm_tipo_tarefa !== "AUDIÊNCIA");
    }
    return items;
  }

  get isProcessCustomer() {
    return ["lawsuit", "expedient", "publication"].includes(this.formType);
  }

  get isEmptyCities() {
    return this.cities.length === 0;
  }

  get states(): State[] {
    return this.$store.state.location.states;
  }

  get hearingTypes() {
    return this.$store.state.acttype.all;
  }

  get isHearingTask(): boolean {
    const selectedTaskType = this.taskTypes.find((item) => item.id === this.cd_tipo_tarefa);
    return selectedTaskType?.nm_tipo_tarefa === "AUDIÊNCIA";
  }

  get isAdministrativeTask(): boolean {
    const selectedTaskType = this.allTaskTypes.find((item) => item.id === this.cd_tipo_tarefa);
    return selectedTaskType?.nm_tipo_tarefa === "ADMINISTRATIVO";
  }

  get employees() {
    const employees = this.$store.state.department.employee.all;

    for (const item of employees) {
      if (item.nm_funcionario) item.nm_titulo = item.nm_funcionario;
      else item.nm_titulo = item.nm_equipe;
    }

    return employees;
  }

  get customers(): LogisticsCustomer[] {
    if (this.isProcessCustomer) return this.$store.state.lawsuit.customer.all;
    return this.$store.state.collection.customer.all;
  }

  get teams(): Team[] {
    return this.$store.state.department.responsible.all;
  }

  get debtors(): Debtor[] {
    return this.$store.state.debtor.all;
  }

  get forums(): Forum[] {
    return this.$store.state.forum.all;
  }

  get hasPermissionCoordinator() {
    return this.$store.state.user.permissions.taskCoordinator;
  }

  get currentTaskType() {
    return this.taskTypes?.find((item) => item.id === this.cd_tipo_tarefa)?.nm_tipo_tarefa?.toLowerCase();
  }

  get maximumExpirationDate() {
    return this.dt_fatal?.includes?.("/") ? new Date(formatDateEn(this.dt_fatal)) : new Date(this.dt_fatal);
  }

  get minimumExpirationDate() {
    const currentDate = new Date();
    const fatalDate = this.maximumExpirationDate;

    return currentDate >= fatalDate ? fatalDate : currentDate;
  }

  @Watch("formType")
  resetCustomer() {
    this.customerQuery = "";
    this.cd_cliente = "";
  }

  @Watch("cd_estado")
  async handleCDStateChanged(cd_estado: number) {
    if (!cd_estado) return;

    this.cityLoading = true;
    this.nm_estado = this.states.find((item) => String(item.id) === String(cd_estado))?.nm_estado || "";
    this.cities = await this.$store.dispatch("location/getCities", { cd_estado });

    this.cityLoading = false;
  }

  @Watch("cd_cidade")
  onUpdateCity() {
    if (!this.cities.length) return;
    this.nm_cidade = this.cities.find((item) => Number(item.id) === Number(this.cd_cidade))?.nm_cidade || "";
  }

  @Watch("hr_vencimento")
  onUpdateHRDiligencia(value: string) {
    const unmask = value.replaceAll("_", "0");
    const hr = Number(unmask.substring(0, 2));
    const min = Number(unmask.substring(3, 5));

    if (hr < 0 || hr > 23 || min < 0 || min > 59) this.hr_vencimento = "";
    else this.hr_vencimento = value;
  }

  @Watch("cd_tipo_tarefa")
  async onUpdateTypeTask() {
    if (this.cd_tipo_tarefa === PROCESSUAL && this.lawsuit?.cd_responsavel_tarefa) this.loadResponsibleLawsuit();
  }

  @Watch("dt_fatal")
  onUpdatePrazoFatal(currentValue?: any) {
    if (this.isEditForm && !currentValue) return;

    const fatalDate = (() => {
      const date = typeof this.dt_fatal === "string" ? this.getDateFatal() : new Date(this.dt_fatal);
      return new Date(date.toISOString().split("T")[0].replaceAll("-", "/"));
    })();
    const currentDate = new Date(new Date().toISOString().split("T")[0].replaceAll("-", "/"));

    let dayCount = 0;
    while (dayCount < 3) {
      if (fatalDate <= currentDate) dayCount = 3;
      else fatalDate.setDate(fatalDate.getDate() - 1);

      if (!this.isWeekend(fatalDate.getDay())) dayCount += 1;
    }

    this.dt_vencimento = formatDate(fatalDate.toString());
    this.getResponsibleInfos();
  }

  @Watch("cities")
  onUpdateCities() {
    if (this.cd_cidade === 0)
      this.cd_cidade = this.cities.find((item) => item.nm_cidade === this.lawsuit?.nm_cidade)?.id || 0;
  }

  @Watch("lawsuitQuery")
  handleProcessNumberUpdate() {
    if (typeof this.lawsuitQuery === "string") this.lawsuitQuery = this.lawsuitQuery.replace(/[^a-z0-9]/gi, "");
  }

  handleDateInput(ref: string) {
    const calendarComponent: any = this.$refs[ref];
    if (!calendarComponent) return;

    const calendarInputComponent: any = calendarComponent.$refs?.input;
    if (!calendarInputComponent) return;

    const calendarInputEl = calendarInputComponent.$el;

    setTimeout(() => {
      calendarInputEl.selectionStart = calendarInputEl.selectionEnd = 100;
    }, 0);
  }

  handleRecurrenceSubmit({ form }: { form: Omit<TaskRecurrenceFormT, "cd_tarefa"> }) {
    this.recurrenceForm = form;
  }

  onAskingToCreateLogisticsActType(ie_tipo_ato?: "AU" | "DI") {
    const params: ModalParams = {
      content: { title: "Cadastrar Tipo de Ato" },
      accept: {
        text: "Cadastrar",
        class: "p-button-success",
        function: this.handleCreateLogisticsActType,
      },
      props: { acttype: { ie_tipo_ato } },
      options: { modal: "act-type" },
    };
    this.$store.dispatch("global/showModal", params);
  }

  handleCloseRecurrenceModal() {
    this.showRecurrenceModal = false;
  }

  async handleCreateLogisticsActType(form: any) {
    await this.$store.dispatch("acttype/create", { form });
    this.getLogisticsActTypeList();
  }

  getDateFatal(): Date {
    const dayCurrent = new Date(formatDateEn(this.dt_fatal));
    dayCurrent.setDate(dayCurrent.getDate() + 1);

    return dayCurrent;
  }

  isWeekend(day: number) {
    return day === 0 || day === 6;
  }

  async searchCustomer(event: any) {
    if (this.isProcessCustomer) {
      await this.$store.dispatch("lawsuit/customer/getAll", {
        filters: { nm_cliente: event.query },
      });
    } else {
      await this.$store.dispatch("collection/customer/getAll", {
        filters: { nm_cliente: event.query },
      });
    }
  }

  searchDebtor(event: any) {
    this.$store.dispatch("debtor/getAll", { filters: { nm_devedor: event.query, page: 1 } });
  }

  async getLogisticsActTypeList() {
    if (!this.$store.state.user.permissions.logisticsModule) return;
    await this.$store.dispatch("acttype/getAll", { filters: { ie_tipo_ato: "AU" } });
  }

  async getResponsibleInfos() {
    this.responsibleLoading = true;

    const employees: { [key: string]: Employee } = {};
    for (const employee of this.team?.cd_funcionarios || []) {
      if (employee.cd_authuser) {
        employees[String(employee.cd_authuser)] = employee;
      }
    }

    try {
      await Promise.allSettled(
        Object.keys(employees).map(async (employeeId) => {
          const employee = employees[employeeId];

          if (employee.cd_authuser) {
            const taskOnDay = await this.$store.dispatch("task/responsibleTaskOnDay", {
              cd: employee.cd_authuser,
              dt_vencimento: formatDateEn(this.dt_vencimento) || undefined,
            });

            employee.prazos_operacional = taskOnDay?.prazos_operacional;
            employee.prazos_processual = taskOnDay?.prazos_processual;
            employee.qtd_prazos_responsavel = taskOnDay?.qtd_prazos_responsavel;
          }
        })
      );
    } finally {
      this.teamEmployees = Object.values(employees);
      this.responsibleLoading = false;
    }
  }

  handleTeamInput(teamId: number) {
    this.team = this.teams?.find((item) => item.id === teamId) || null;
    this.nm_equipe = this.team?.nm_equipe;
    this.cd_responsaveis = [];

    this.getResponsibleInfos();
  }

  onAskingResponsible() {
    const params: ModalParams = {
      content: {
        title: "Adicionar Responsável",
        text: "Deseja manter o funcionario como responsavel pelas futuras tarefas desse processo?",
      },
      accept: { text: "Sim", function: this.addResponsibleLawsuit },
      recuse: { text: "Não" },
      options: { useFooter: true, closable: true },
    };

    this.$store.dispatch("global/showModal", params);
  }

  onAskingChangeResponsible() {
    const params: ModalParams = {
      content: {
        title: "Mudar Responsável",
        text: "Deseja alterar o responsavel pelas futuras tarefas desse processo para esse funcionario?",
      },
      accept: { text: "Sim", function: this.addResponsibleLawsuit },
      recuse: { text: "Não" },
      options: { useFooter: true, closable: true },
    };

    this.$store.dispatch("global/showModal", params);
  }

  async addResponsibleLawsuit() {
    await this.$store.dispatch("lawsuit/edit", {
      cd: this.lawsuit?.id,
      form: { cd_responsavel_tarefa: this.cd_responsaveis[this.cd_responsaveis.length - 1] },
    });
  }

  onResponsibleUnselected(event: any) {
    const index = this.cd_responsaveis.indexOf(event.value?.cd_authuser);
    if (index > -1) {
      this.cd_responsaveis.splice(index, 1);
    }
  }

  selectCustomer(event: any) {
    this.cd_cliente = event.value?.id || 0;
  }

  onDebtorSelected(event: any) {
    this.debtor = event.value || null;
    this.cd_devedor = String(this.debtor?.id || 0);
    this.customerQuery = this.debtor?.cd_cliente?.nm_cliente || "";
    this.cd_cliente = String(this.debtor?.cd_cliente?.id) || "";

    if (this.debtor?.endereco_set?.length) {
      const address = this.debtor.endereco_set[0];

      this.cd_estado = this.states.find((item) => item.nm_estado === address?.nm_estado)?.id || 0;
      this.cd_cidade = Number(address.cd_cidade) || 0;
    }
  }

  async searchLawsuit(event: any) {
    this.lawsuits =
      (
        await this.$store.state.lawsuit.service.getAll(
          { page: 1, nr_por_pagina: 12, paginacao: true },
          { nr_processo: event.query }
        )
      )?.data?.results || [];
  }

  async onLawsuitSelected(event: any) {
    this.nr_processo = event.value?.nr_processo || "";

    this.lawsuitLoading = true;
    const response = await this.$store.state.lawsuit.service.getWithNumber(this.nr_processo);
    this.lawsuit = response?.data?.length ? response.data[0] : null;
    this.cd_estado = this.states.find((item) => item.nm_estado === this.lawsuit?.nm_estado)?.id || 0;
    this.cd_cidade = this.cities.find((item) => item.nm_cidade === this.lawsuit?.nm_cidade)?.id || 0;
    this.nm_parte_contraria =
      this.lawsuit?.ie_posicao_cliente === "A" ? this.lawsuit?.nm_reu || "" : this.lawsuit?.nm_autor || "";
    this.lawsuitLoading = false;

    if (this.lawsuit?.cd_cliente) {
      const response = await this.$store.dispatch("lawsuit/customer/get", { cd: this.lawsuit.cd_cliente });
      this.cd_cliente = this.lawsuit.cd_cliente;
      this.customerQuery = response;
    }

    if (!this.isEditForm && this.lawsuit?.cd_responsavel_tarefa) {
      const response = await this.$store.dispatch("department/employee/search", {
        cd_authuser: this.lawsuit?.cd_responsavel_tarefa,
      });

      this.responsibleQuery = [response?.data?.results?.[0]];
    }

    if (this.lawsuit?.cd_responsavel_tarefa && this.cd_tipo_tarefa === PROCESSUAL) {
      await this.loadResponsibleLawsuit();
    }

    if (this.lawsuit?.ie_estrategico) {
      try {
        const strategyResponse = await this.$store.state.lawsuit.service.getStrategies(this.lawsuit.id);
        this.strategies = strategyResponse.data || [];
      } catch {
        this.strategies = [];
      }
    }

    if (this.strategies.length && this.$route.name?.includes("task-")) {
      const params: ModalParams = {
        content: {
          title: `Estratégia ${this.strategies.length > 1 ? "(Atual)" : ""} do Processo`,
          text: this.strategies[0].ds_estrategia,
        },
        options: {
          style: {
            modal: { maxWidth: "480px", borderRadius: "8px", overflow: "hidden" },
            content: { fontSize: "14px", lineHeight: "18px" },
          },
          useFooter: false,
          closable: true,
        },
      };

      this.$store.dispatch("global/showModal", params);
    }
  }

  async loadResponsibleLawsuit() {
    const employees = await this.$store.dispatch("department/employee/search", {
      cd_authuser: this.lawsuit?.cd_responsavel_tarefa,
    });

    const employee = employees.data.results[0];
    if (employee && !this.cd_responsaveis.includes(employee.cd_authuser)) {
      employee.nm_titulo = employee?.nm_funcionario;
      this.cd_responsaveis.push(employee?.cd_authuser || 0);
      this.responsibleQuery.push(employee);
    }
  }

  async showLawsuitPreview() {
    let modal: ModalParams = {};
    if (this.lawsuit)
      modal = {
        content: { title: `Processo de Número ${this.nr_processo}` },
        accept: { text: "Confirmar" },
        props: { lawsuit: this.lawsuit },
        options: { modal: "lawsuit-preview" },
      };
    else
      modal = {
        content: {
          title: "Nenhum Processo Encontrado!",
          text: `O processo de número ${this.nr_processo} não foi encontrado.`,
        },
      };

    this.$store.dispatch("global/showModal", modal);
  }

  hideFileUploadButton() {
    this.fileUpload.$children.forEach((element: any) => {
      if (element.label === "remove") element.$el.style.display = "none";
    });
  }

  validate() {
    const form = this.getFields();

    this.validation = validate(form);
    if (this.validation.error) {
      const messages: ToastParams[] = [{ severity: "warn", summary: this.validation.error }];
      this.$store.commit("global/updateToast", messages);

      const field: any = this.$refs[this.validation.field || ""];
      if (field) field.$el.scrollIntoView(true);

      return false;
    }

    return true;
  }

  getFields() {
    const fields: TaskForm = {
      cd_cliente: this.cd_cliente,
      cd_devedor: this.formType === "debtor" ? this.cd_devedor : undefined,
      cd_cidade: this.cd_cidade,
      cd_equipe: this.cd_equipe,
      nm_equipe: this.nm_equipe,
      cd_responsaveis: this.cd_responsaveis.map((item) => Number(item)).filter((item) => item),
      cd_tipo_tarefa: this.cd_tipo_tarefa,
      nm_titulo: this.nm_titulo,
      nm_parte_contraria: this.formType !== "debtor" ? this.nm_parte_contraria : "",
      nr_processo: this.nr_processo || this.lawsuitQuery,
      ds_descricao: this.ds_descricao,
      dt_vencimento: formatDateEn(this.dt_vencimento),
      hr_vencimento: formatHours(this.hr_vencimento),
      nm_cidade: this.nm_cidade,
      nm_estado: this.nm_estado,
      dt_fatal: this.dt_fatal ? formatDateEn(this.dt_fatal) : undefined,
      recurrence: this.recurrenceForm as any,
    };

    if (this.isHearingTask) {
      fields.nm_autor = this.nm_autor;
      fields.nm_reu = this.nm_reu;
      fields.cd_foro = this.cd_foro;
      fields.cd_tipo = this.cd_tipo;
      fields.ie_processo_virtual = this.ie_processo_virtual;
      fields.hasCorrespondent = this.hasCorrespondent;
      fields.isCoordinator = this.hasPermissionCoordinator;
      fields.isHearingTask = this.isHearingTask;
    }

    if (this.isAdministrativeTask) {
      delete fields.cd_cliente;
    }

    if (this.cd_expediente) {
      fields.cd_expediente = String(this.cd_expediente);
      fields.dt_expedicao = this.dt_expedicao;
      fields.prazo = Number(this.prazo);
    }

    if (!this.isEditForm) {
      fields.ie_modulo_origem = this.task?.ie_modulo_origem || undefined;

      if (this.formType === "administrative") {
        fields.cd_tipo_tarefa = this.allTaskTypes.find((item) => item.nm_tipo_tarefa === "ADMINISTRATIVO")?.id;
      }
    }

    return fields;
  }

  handleFileUpload({ files }: { files: File[] }) {
    this.files = files;
  }

  onSubmit() {
    if (!this.validate()) return;

    this.fileUpload.upload();
    const form = this.getFields();

    this.$emit("submit", { form }, this.files);
  }

  async getComponentData() {
    if (this.task.dt_fatal) this.onUpdatePrazoFatal();
    await this.$store.dispatch("location/getStates");

    if (this.nm_estado) {
      this.cd_estado = this.states.find((item) => item.nm_estado === this.nm_estado)?.id || 0;
      if (this.cd_cidade) this.cd_cidade = Number(this.cd_cidade);
    }

    if (this.cd_responsaveis.length > 0) {
      for (const responsible of this.cd_responsaveis) {
        if (!Number(responsible)) continue;

        const response = await this.$store.dispatch(
          "department/employee/search",
          { cd_authuser: responsible },
          { root: true }
        );
        let item = response.data.results[0];
        item.nm_titulo = item.nm_funcionario;
        this.responsibleQuery.push(item);
      }
    }
    await this.$store.dispatch("task/getTypes");

    if (this.isEditForm && this.cd_equipe) {
      this.team = this.teams?.find((item) => item.id === this.cd_equipe) || null;
      this.nm_equipe = this.team?.nm_equipe;

      this.getResponsibleInfos();
      this.recurrenceForm = await this.$store.state.task.service.getRecurrence({ cd_tarefa: this.cd });
    }

    if (!this.isEditForm && this.formType === "administrative") {
      this.cd_tipo_tarefa =
        this.allTaskTypes.find((item) => item.nm_tipo_tarefa === "ADMINISTRATIVO")?.id || this.cd_tipo_tarefa;
    }
  }

  created() {
    if (this.debtorQuery && this.task.ob_devedor) this.searchDebtor({ query: this.task.ob_devedor.nm_devedor });
    this.getComponentData();
    this.$store.dispatch("department/responsible/getAll");
    this.getLogisticsActTypeList();

    if (this.isHearingTask) {
      this.$store.dispatch("forum/getAll");
    }

    const hasProcesso = !!this.nr_processo;
    const isExpedientRoute = this.$route.name === "expedients";
    const isIntegrationForm = ["publication", "movement"].includes(this.formType);
    if (hasProcesso && (isExpedientRoute || isIntegrationForm)) {
      this.onLawsuitSelected({ value: { nr_processo: this.nr_processo } });
    }
  }

  mounted() {
    this.hideFileUploadButton();
  }
}
</script>

<style lang="scss">
.c-task-form {
  --padding: 0;

  .task-recurrence-option {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 4px;
    margin: 12px 0 24px;

    .task-recurrence-button {
      padding: 10px 14px;
      border-radius: var(--border-radius-50);
      border: 1px solid var(--success-color);
      outline: none;
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.25px;
      cursor: pointer;
      background-color: var(--success-color);

      &.edit {
        color: var(--success-color);
        background-color: transparent;
      }

      i {
        margin-right: 6px;
      }
    }
  }

  .c-form-field-template.lawsuit-search {
    .p-autocomplete-dropdown {
      border-radius: 0 var(--border-radius-100) var(--border-radius-100) 0 !important;
    }

    .fa-search {
      color: #fff;
      pointer-events: none;
      margin-right: 0.4rem;
    }

    .pi-chevron-down.p-button-icon {
      display: none;
    }
  }

  .c-dashboard-section-template.adicional {
    .c-form-field-template {
      width: 100% !important;
      max-width: unset !important;

      .p-inputtextarea {
        width: 100%;
      }
    }
  }
}
</style>
