import { City } from "@/store/location/types";

export function addUTC(date?: string) {
  if (!date) return "";
  if (typeof date !== "string" || date.length > 10) return date;

  return date.replaceAll("-", "/");
}

export function formatDate(date?: string) {
  try {
    if (!date) return "";

    return Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(new Date(addUTC(date)));
  } catch {
    return "";
  }
}

export function formatMonthDate(date?: string) {
  try {
    if (!date) return "";
    if (date.toString()?.includes("/") && date.toString().length < 12) date = formatDateEn(date);

    return Intl.DateTimeFormat("pt-BR", {
      month: "2-digit",
      year: "numeric",
    }).format(new Date(addUTC(date)));
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error);
    return "";
  }
}

export function formatHours(hours?: string) {
  try {
    if (!hours) return "";
    if (hours.length < 5) hours = `0${hours}`;

    const hour = Number(hours.slice(0, 2));
    const min = Number(hours.slice(3, 5));

    return Intl.DateTimeFormat("pt-BR", {
      hour: "numeric",
      minute: "numeric",
    }).format(new Date().setHours(hour, min));
  } catch {
    return "";
  }
}

export function formatDateTime(date?: string, hour?: string) {
  if (date?.includes("T")) {
    try {
      const [newDate, newHour] = date.split("T");
      date = newDate;
      hour = newHour.substring(0, 5);
    } catch {
      hour = "";
    }
  }

  if (!date) return "";
  if (!hour) return formatDate(date);

  return Intl.DateTimeFormat("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(`${addUTC(date)} ${hour}`));
}

export function formatDateEn(date: any): string {
  try {
    if (!(typeof date === "string" && date.includes("/"))) date = formatDate(date);

    return date.split("/").reverse().join("-");
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error);

    return "";
  }
}

export function formatDateTimeEn(date: string, hour: string) {
  return `${formatDateEn(date)} ${hour}`;
}

export function formatDateLong(date?: string) {
  if (!date) return "";

  const formattedDate = Intl.DateTimeFormat("pt-BR", {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(new Date(addUTC(date)));

  return formattedDate;
}

export function formatMonthDateLong(date?: string) {
  if (!date) return "";

  const formattedDate = Intl.DateTimeFormat("pt-BR", {
    month: "long",
    year: "numeric",
  }).format(new Date(addUTC(date)));

  return formattedDate;
}

export function formatDateTimeLong(date?: string, hour?: string) {
  if (date?.includes("T")) {
    try {
      const [newDate, newHour] = date.split("T");
      date = newDate;
      hour = newHour.substring(0, 5);
    } catch {
      hour = "";
    }
  }

  if (date && !hour) return formatDateLong(date);
  if (!date) return "";

  return Intl.DateTimeFormat("pt-BR", {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(`${addUTC(date)} ${hour}`));
}

export const dayNames = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
export const monthNames = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export const calendarConfig = {
  firstDayOfWeek: 0,
  dayNames: dayNames,
  dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
  dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
  monthNames: monthNames,
  monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
  today: "Hoje",
  clear: "Limpar",
  dateFormat: "dd/mm/yy",
};

export function formatNumberToString(value?: number | string) {
  if (value === undefined) return "";

  const newValue = value.toString();
  if (newValue.includes(".")) return newValue;

  return `${newValue}.00`;
}

export function formatComarca(cd_cidade?: City) {
  if (!cd_cidade?.nm_cidade || !cd_cidade.ds_uf) return "";
  return `${cd_cidade.nm_cidade} / ${cd_cidade.ds_uf}`;
}

export function formatComarcaLong(cd_cidade?: City) {
  if (!cd_cidade?.nm_cidade || !cd_cidade.cd_estado?.nm_estado) return "";
  return `${cd_cidade.nm_cidade} / ${cd_cidade.cd_estado?.nm_estado}`;
}

export function formatComarcaByName(city?: string | null, state?: string | null): string {
  return [city, state].filter(Boolean).join(" / ");
}

export function formatObjectToCSV(items: any[]) {
  const header = Object.keys(items[0]);
  const csvArray = items.map((row) =>
    header.map((fieldName) => JSON.stringify(row[fieldName], (key, value) => (value === null ? "" : value))).join(",")
  );
  csvArray.unshift(header.join(","));
  const csv = csvArray.join("\r\n");

  return csv;
}

export function formatObjectToBlobCSV(items: any[]) {
  const csv = formatObjectToCSV(items);
  const blob = new Blob([csv], { type: "text/csv" });

  return blob;
}

export function getHourInDate(date?: string) {
  let hour = "";

  try {
    hour = date?.split("T")[1].substring(0, 5) || "";
    return hour;
  } catch {
    return hour;
  }
}

export function formatPhone(phone?: string) {
  try {
    if (!phone) return "";
    phone = phone.replaceAll(" ", "");

    // (99) 99999-9999
    if (phone.length === 11) return `(${phone.slice(0, 2)}) ${phone.slice(2, 7)}-${phone.slice(7)}`;

    if (phone.length === 10) return `(${phone.slice(0, 2)}) ${phone.slice(2, 6)}-${phone.slice(6)}`;

    return phone;
  } catch {
    return phone || "";
  }
}

export function formatFileName(aq_arquivo = ""): string {
  try {
    const fileName = aq_arquivo.split("arquivo/")[1].split("?X-Amz")[0];
    return decodeURIComponent(fileName);
  } catch {
    return aq_arquivo;
  }
}

export function formatLiminarPreview(text: string) {
  return text.length >= 80 ? `${text.slice(0, 80)}...` : text;
}

export function formatMovePreview(text: string) {
  try {
    text = text.replace(/<\/p>/g, " ").replace(/<\/?[^>]+(>|$)/g, "");
    return text.length >= 80 ? `${text.slice(0, 80)}...` : text;
  } catch (error) {
    return text || "...";
  }
}

export function formatCPF(value?: string) {
  if (!value) return "";

  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
}

export function formatCPFToLGPD(value?: string) {
  try {
    if (!value) return "";
    return `***.${formatCPF(value).slice(4, 11)}-**`;
  } catch {
    return value || "";
  }
}

export function formatLargeText(text: string, limit = 26) {
  try {
    return `${text.slice(0, limit)}${text.length > limit ? "..." : ""}`;
  } catch {
    return text;
  }
}

export function formatProcessNumberInCnjPattern(processNumber?: string, enableLog = false) {
  try {
    if (!processNumber) throw "Process number not found";
    if (processNumber.length < 20) throw "Process number lower than expected";

    if (/[A-Za-z]/.test(processNumber)) return processNumber;

    const sliceA = `${processNumber.slice(0, 7)}-${processNumber.slice(7, 9)}`; // 0804774-95
    const sliceB = `${processNumber.slice(9, 13)}.${processNumber.slice(13, 14)}`; // 2022.8
    const sliceC = `${processNumber.slice(14, 16)}.${processNumber.slice(16)}`; // 10.0040

    // 0804774-95.2022.8.10.0040
    return `${sliceA}.${sliceB}.${sliceC}`;
  } catch (error: any) {
    if (enableLog && process.env.NODE_ENV === "development") console.error(error);
    return processNumber || "";
  }
}

export function sortMovementByDate(movements: any[]) {
  return movements.sort((movementA, movementB) =>
    `${movementA.dt_movimentacao} ${movementA.hr_movimentacao}` >
    `${movementB.dt_movimentacao} ${movementB.hr_movimentacao}`
      ? -1
      : 1
  );
}

export function fixDecimals(value: number, decimals = 2): string {
  try {
    const factor = Math.pow(10, decimals);
    const fixedValue = Math.floor(value * factor) / factor;

    return fixedValue.toFixed(decimals);
  } catch {
    return String(value || 0);
  }
}

export function formatCNPJ(value?: string) {
  try {
    if (!value) return "";

    return value
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d)/, "$1.$2")
      .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      .replace(/\.(\d{3})(\d)/, ".$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .slice(0, 18);
  } catch {
    return "";
  }
}

export function formatTax(tax?: string) {
  try {
    tax = tax?.replace(/\D/g, "");
    if (!tax?.length) return "";

    if (tax.length <= 11) return formatCPF(tax);
    return formatCNPJ(tax);
  } catch {
    return "";
  }
}

export function removeFormatTax(tax?: string) {
  try {
    return tax?.replace(/\D/g, "") || "";
  } catch {
    return "";
  }
}
