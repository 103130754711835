import { useAppStore } from "@/stores/app";

export function showErrorInDevelopMode(error?: unknown, others?: any) {
  if (process.env.NODE_ENV !== "development") {
    return;
  }

  /* eslint no-console: "off" */
  console.error(error, others);
}

export function showApiError(error: any) {
  const appStore = useAppStore();

  if (typeof error === "object") {
    const errorKey = Object.keys(error)?.[0];
    if (!errorKey) {
      return;
    }

    const title = error?.title || "Ocorreu um erro";
    const message = error?.detail || (Array.isArray(error[errorKey]) ? error[errorKey].join(", ") : error[errorKey]);
    const description = `${Array.isArray(message) ? message.join("") : message}`;

    appStore.showToast({ title, description: description === title ? "" : description, severity: "error" });
  } else if (typeof error === "string") {
    if (error.length < 300) {
      appStore.showToast({ title: "Ocorreu um erro", description: error, severity: "error" });
    } else {
      appStore.showToast({ title: "Erro inesperado!", description: "Tente novamente mais tarde.", severity: "error" });
    }
  }

  showErrorInDevelopMode(error);
}
