import { reactive } from "vue";
import { defineStore } from "pinia";

import { gojus } from "@/services/gojusv2";
import { VUE_APP_API_INTEGRATION_URL } from "@/utils/env";

import type {
  JuridicalAutomaticProcessRegistrationFormT,
  JuridicalAutomaticProcessRegistrationT,
} from "@/stores/juridical/automatic-process-registration/types";

export const useJuridicalAutomaticProcessRegistrationStore = defineStore(
  "juridical-automatic-process-registration",
  () => {
    const state = reactive({
      retrieve: { id: 0, habilitado: false } as JuridicalAutomaticProcessRegistrationT,
    });

    const loading = reactive({
      retrieve: false,
      create: false,
      update: false,
    });

    const juridicalAutomaticProcessRegistrationUtils = useJuridicalAutomaticProcessRegistrationUtils();

    async function retrieve() {
      loading.retrieve = true;
      const response = await gojus.get<JuridicalAutomaticProcessRegistrationT[]>("v1/cadastro-automatico/", {
        apiUrl: VUE_APP_API_INTEGRATION_URL,
        useCompany: true,
      });
      loading.retrieve = false;

      if (!response.data) {
        return null;
      }

      const result = juridicalAutomaticProcessRegistrationUtils.handle(response.data);
      const item = result[0] || null;

      if (item) {
        state.retrieve = item;
      }

      return item;
    }

    async function create(payload: { form: JuridicalAutomaticProcessRegistrationFormT }) {
      loading.create = true;
      const response = await gojus.post<JuridicalAutomaticProcessRegistrationT>(
        "v1/cadastro-automatico/",
        payload.form,
        {
          apiUrl: VUE_APP_API_INTEGRATION_URL,
          useCompany: true,
        }
      );
      loading.create = false;

      if (response?.data?.id) {
        const items = juridicalAutomaticProcessRegistrationUtils.handle([response.data]);

        if (items[0]) {
          state.retrieve = items[0];
        }
      }

      return { success: true };
    }

    async function update(payload: { id: number; form: JuridicalAutomaticProcessRegistrationFormT }) {
      loading.update = true;
      const response = await gojus.patch(`v1/cadastro-automatico/${payload.id}/`, payload.form, {
        apiUrl: VUE_APP_API_INTEGRATION_URL,
        useCompany: true,
      });
      loading.update = false;

      if (!response.data) {
        return { success: false };
      }

      return { success: true };
    }

    return {
      state,
      loading,
      retrieve,
      create,
      update,
    };
  }
);

export function useJuridicalAutomaticProcessRegistrationUtils() {
  function form(item?: JuridicalAutomaticProcessRegistrationT | null): JuridicalAutomaticProcessRegistrationFormT {
    return {
      habilitado: item?.habilitado ?? false,
    };
  }

  function handle(items: JuridicalAutomaticProcessRegistrationT[]) {
    return items;
  }

  return {
    form,
    handle,
  };
}
