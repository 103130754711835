import { toPng, toJpeg } from "html-to-image";
import jsPDF from "jspdf";

import { AnalyticsTask } from "@/store/task/types";
import { formatDateEn } from "@/utils/formatters";

async function exportAsImage(selector: string, format: "png" | "jpeg" = "png") {
  try {
    const itemEl = document.querySelector<HTMLDivElement>(selector);
    if (!itemEl) return;

    const imageURL = await (format === "png" ? toPng(itemEl) : toJpeg(itemEl));
    if (!imageURL) return;

    const linkEl = document.createElement("a");
    linkEl.href = imageURL;
    linkEl.download = `${selector.replaceAll("#", "")}.${format}`;
    linkEl.click();
  } catch (error: any) {
    if (process.env.NODE_ENV === "development") console.error(error);
  }
}

async function exportAsPDF(selector: string) {
  try {
    const jsPDFDoc = new jsPDF();

    const itemEl = document.querySelector<HTMLDivElement>(selector);
    if (!itemEl) return;

    const imageData = await toPng(itemEl);
    if (!imageData) return;

    jsPDFDoc.addImage(imageData, "png", 15, 40, 180, 200);
    jsPDFDoc.save(selector.replaceAll("#", ""));
  } catch (error: any) {
    if (process.env.NODE_ENV === "development") console.error(error);
  }
}

export const exportDashboardCardOption = (elementId: string) => ({
  label: "Exportar",
  icon: "fas fa-file-export",
  items: [
    { label: "PDF", icon: "fas fa-file-pdf", command: () => exportAsPDF(`#${elementId}`) },
    { label: "PNG", icon: "fas fa-file-image", command: () => exportAsImage(`#${elementId}`) },
    { label: "JPEG", icon: "fas fa-image", command: () => exportAsImage(`#${elementId}`, "jpeg") },
  ],
});

export const collectionDashboardCards = {
  debtorTotal: {
    label: "Total de Devedores",
    value: [] as any[],
    response: {} as any,
    endpoint: "total-devedores-com-e-sem-acordo",
    menuOptions: [exportDashboardCardOption("debtor-total")],
  },
  debtorWithoutAgreement: {
    label: "Devedores sem Acordos",
    value: [] as any[],
    response: {} as any,
    endpoint: "total-devedores-sem-acordo",
    menuOptions: [exportDashboardCardOption("debtor-without-agreement")],
  },
  debtorUpdates: {
    label: "Atualizações no Mês",
    value: [] as any[],
    response: {} as any,
    endpoint: "total-atualizacoes",
    menuOptions: [exportDashboardCardOption("debtor-updates")],
  },
  averageTimeWithoutUpdates: {
    label: "Tempo Médio sem Atualizações",
    value: [] as any[],
    response: {} as any,
    endpoint: "movimentos-valor-debito",
    menuOptions: [exportDashboardCardOption("average-time-without-updates")],
  },
  totalUpdatesInTheMonth: {
    label: "Total de Movimentações Internas no Mês",
    endpoint: "movimentos-funcionarios",
    value: {} as { [username: string]: { [day: string]: any } },
    subtitles: [] as { label: string; value: number; color: string }[],
    response: {} as any,
    menuOptions: [exportDashboardCardOption("total-updates-in-the-month")],
  },
  numberOfDebtorsByType: {
    label: "Quantidade de Devedores por Tipo",
    value: [] as any[],
    response: {} as any,
    endpoint: "total-judicial-extrajudicial",
    menuOptions: [exportDashboardCardOption("number-of-debtors-by-type")],
  },
  debtorsWithoutRegisteredContact: {
    label: "Devedores sem Contato Cadastrado",
    value: { current: 35, total: 100 },
    menuOptions: [exportDashboardCardOption("debtors-without-registered-contact")],
  },
  debtorsWithIncompleteRegistrations: {
    label: "Devedores com Cadastros Incompletos",
    value: { current: 66, total: 100 },
    menuOptions: [exportDashboardCardOption("debtors-with-incomplete-registrations")],
  },
  debtorsWithoutUpdatedDebt: {
    label: "Devedores sem Débito Atualizado",
    value: { current: 35, total: 100 },
    menuOptions: [exportDashboardCardOption("debtors-without-updated-debt")],
  },
  debtorsWithFees: {
    label: "Devedores com Honorários",
    value: { current: 29, total: 100 },
    menuOptions: [exportDashboardCardOption("debtors-with-fees")],
  },
  totalAmountOfRegisteredFees: {
    label: "Valor Total dos Honorários Cadastrados",
    value: { current: 78, total: 100 },
    menuOptions: [exportDashboardCardOption("total-amount-of-registered-fees")],
  },
  recoveredAndReceivableFees: {
    label: "Honorários Recuperados / Honorários a Receber",
    value: { current: 30, total: 100 },
    menuOptions: [exportDashboardCardOption("recovered-and-receivable-fees")],
  },
  typeOfDebtors: {
    label: "Tipo de Devedores",
    value: [] as any[],
    response: {} as any,
    endpoint: "total-pf-pj",
    menuOptions: [exportDashboardCardOption("type-of-debtors")],
  },
  updatesComparedToLastMonth: {
    label: "Movimentações Internas Comparadas Com O Mês Anterior",
    endpoint: "comparacao-atualizacoes",
    value: {} as { current: any[]; last: any[] },
    subtitles: [] as { label: string; color: string }[],
    response: {} as any,
    menuOptions: [exportDashboardCardOption("updates-compared-to-last-month")],
  },
};

export const taskDashboardCards = {
  numberOfDeadlinesForTheDay: {
    label: "Quantidade de Prazos do Dia",
    endpoint: "total-prazos-dia",
    value: [] as any[],
    menuOptions: [exportDashboardCardOption("number-of-deadlines-for-the-day-chart")],
  },
  numberOfTasksCompleted: {
    label: "Quantidade de Tarefas Concluídas",
    endpoint: "total-concluidas",
    value: [] as any[],
    menuOptions: [exportDashboardCardOption("number-of-tasks-completed-chart")],
  },
  numberOfDeadlinesToExpire: {
    label: "Quantidade de Prazos a Vencer",
    endpoint: "total-a-vencer",
    value: [] as any[],
    menuOptions: [exportDashboardCardOption("number-of-deadlines-to-expire")],
  },
  finished: {
    label: "Tarefas Concluídas",
    endpoint: "concluidas",
    value: null as AnalyticsTask[] | null,
    info: "concluidas",
    menuOptions: [exportDashboardCardOption("finished-chart")],
  },
  deadlinesOfTheDay: {
    label: "Prazos do Dia",
    endpoint: "prazos-dia",
    value: null as AnalyticsTask[] | null,
    info: "prazos-dia",
    menuOptions: [exportDashboardCardOption("deadlines-of-the-day-chart")],
  },
  toExpire: {
    label: "Distribuição de Tarefas por Usuários (a vencer)",
    endpoint: "a-vencer",
    value: null as AnalyticsTask[] | null,
    info: "a-vencer",
    menuOptions: [exportDashboardCardOption("to-expire-chart")],
  },
  expired: {
    label: "Tarefas Vencidas (Total por Usuários)",
    endpoint: "vencidas",
    value: null as AnalyticsTask[] | null,
    info: "vencidas",
    menuOptions: [exportDashboardCardOption("expired-chart")],
  },
  rescheduled: {
    label: "Tarefas Reagendadas",
    endpoint: "reagendadas-ontem-hoje",
    value: [] as any[],
    info: "reagendadas-ontem-hoje",
    menuOptions: [exportDashboardCardOption("rescheduled-chart")],
  },
};

export function getSlicePeriodDate(days: number, operation: "+" | "-" = "+", initialDate = "") {
  const dateObj = initialDate ? new Date(initialDate) : new Date();

  const dt_inicial = formatDateEn(dateObj);
  const dt_final = formatDateEn(
    new Date(dateObj.setDate(operation === "+" ? dateObj.getDate() + days : dateObj.getDate() - days))
  );

  return { dt_inicial, dt_final };
}

export function getWeekPeriodDate() {
  const dateObj = new Date();
  const firstDayOfWeek = dateObj.getDate() - dateObj.getDay();

  const dt_inicial = formatDateEn(new Date(dateObj.setDate(firstDayOfWeek)));
  const dt_final = formatDateEn(new Date(dateObj.setDate(dateObj.getDate() + 6)));

  return { dt_inicial, dt_final };
}

export function getMonthPeriodDate() {
  const dateObj = new Date();
  const firstDayOfMonth = 1;

  const dt_inicial = formatDateEn(new Date(dateObj.setDate(firstDayOfMonth)));
  const dt_final = formatDateEn(new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0));

  return { dt_inicial, dt_final };
}

export function getYearPeriodDate() {
  const dateObj = new Date();
  const firstDayOfYear = 1;
  const firstMonthOfYear = 0;
  const lastMonthOfYear = 12;

  const dt_inicial = formatDateEn(new Date(dateObj.getFullYear(), firstMonthOfYear, firstDayOfYear));
  const dt_final = formatDateEn(new Date(dateObj.getFullYear(), lastMonthOfYear, 0));

  return { dt_inicial, dt_final };
}

export function getRandomColorByString(stringValue: string) {
  try {
    let hash = 0;
    for (let index = 0; index < stringValue.length; index++) {
      hash = stringValue.charCodeAt(index) + ((hash << 5) - hash);
    }
    let r = (hash >> 16) & 0xff;
    let g = (hash >> 8) & 0xff;
    let b = hash & 0xff;

    r = Math.max(80, Math.min(220, r));
    g = Math.max(80, Math.min(220, g));
    b = Math.max(80, Math.min(220, b));

    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
  } catch (error: any) {
    if (process.env.NODE_ENV === "development") console.error(error);
    return "#52525b";
  }
}

export function getTextColorForBackground(hexColor: string) {
  try {
    const hexToRgb = (hex: string) => {
      const bigint = parseInt(hex.slice(1), 16);
      return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
      };
    };

    const { r, g, b } = hexToRgb(hexColor);

    const luminance = (channel: number) => {
      const normalized = channel / 255;
      return normalized <= 0.03928 ? normalized / 12.92 : Math.pow((normalized + 0.055) / 1.055, 2.4);
    };

    const relativeLuminance = 0.2126 * luminance(r) + 0.7152 * luminance(g) + 0.0722 * luminance(b);
    const isWhite = relativeLuminance > 0.5;

    return { color: isWhite ? "#52525b" : "#fcfcfc", isWhite };
  } catch (error: any) {
    if (process.env.NODE_ENV === "development") console.error(error);
    return { color: "#fcfcfc", isWhite: false };
  }
}
